class RoleUtility {
  isEntity = (role) => {
    if (!role) throw new Error("Missing argument role");

    return role?.typeOf?.includes("ENTITY");
  };

  isNotEntity = (role) => !this.isEntity(role);

  constructRolesAndEntitiesSet = (allRoles, isEntityPresent) => {
    if (!allRoles) throw new Error("Missing argument allRoles");

    let rolesOnly = [];
    let entitiesOnly = [];
    allRoles.forEach((r) => {
      const roleObject = {
        id: r.id,
        name: r.name,
        value: r.id,
        label: r.name,
        title: r.name,
        disabled: r.defaultRole,
        description: r.description,
        product: r.product,
      };

      const entityObject = {
        id: r.id,
        name: r.name,
        value: r.id,
        label: r.name,
        title: r.name,
        description: r.description,
        product: r.product,
      };

      if (isEntityPresent) {
        if (this.isEntity(r)) {
          entitiesOnly.push(entityObject);
          return;
        }
      }

      rolesOnly.push(roleObject);
    });

    return isEntityPresent ? { rolesOnly, entitiesOnly } : { rolesOnly };
  };
}

const roleUtility = new RoleUtility();
export default roleUtility;
