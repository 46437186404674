/* eslint-disable react/prop-types */

import { useState, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { RoleEntityShowMore } from "../RoleEntityShowMore";
import UserNameAvatar from "../../components/UserNameAvatar/UserNameAvatar";
import "./GeneralColumns.css";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Box, InputAdornment, Link, Stack, Tooltip } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import DropdownTreeSelect from "react-dropdown-tree-select";
import { treeUtil } from "../TreeUtils/TreeUtils";
import useGetUsersList from "../../hooks/useGetUsersList/useGetUsersList";
import GetSelectedLabel from "../GetSelectedLabel/GetSelectedLabel";
import Filters from "../DataHelpers/Filters";
import { useNavigate, useParams } from "react-router";
import OrganizationList from "../../hooks/OrganizationList/OrganizationList";
import RoleList from "../../hooks/RoleList/RoleList";
import { useTenant } from "../../contexts/TenantService";
import roleUtility from "../RoleUtility/RoleUtility";
import { FormatDateTimeReport } from "../FormatDateTime/FormatDateTime";
import { dateFormat } from "../Constants/Constants";
import { ProcessRolesProductBased } from "../ProcessRolesProductBased";

export const GeneralColumns = () => {
  let navigate = useNavigate();
  const { tenant } = useParams();
  const OrgList = OrganizationList();
  const RolesList = RoleList();
  const [selectedValues, setSelectedValues] = useState([]);
  const [selectOrgLabel, setSelectOrgLabel] = useState("");
  const [dropdownTreeMeta, setDropdownTreeMeta] = useState();
  const [rolesselectedValues, setRolesSelectedValues] = useState([]);
  const [entitieselectedValues, setEntitieSelectedValues] = useState([]);
  const { data: allUsers } = useGetUsersList();
  const selectRoleLabelRef = useRef();
  const selectEntiteLabelRef = useRef();
  const { tenantSvc } = useTenant();
  let roleEntityList;
  let roleFilterSelect = [];
  let entityFilterSelect = [];
  if (RolesList?.allRoles?.data) {
    roleEntityList = roleUtility.constructRolesAndEntitiesSet(
      RolesList?.allRoles?.data,
      tenantSvc?.featureToggle?.isEntityPresent
    );
  }

  if (tenantSvc?.featureToggle?.showRolesByProduct) {
    ProcessRolesProductBased(roleEntityList?.rolesOnly, roleFilterSelect, true);
    ProcessRolesProductBased(roleEntityList?.entitiesOnly, entityFilterSelect, true);
  } else {
    ProcessRolesProductBased(roleEntityList?.rolesOnly, roleFilterSelect, false);
    ProcessRolesProductBased(roleEntityList?.entitiesOnly, entityFilterSelect, false);
  }

  const orgLabelFunc = (orgs) => {
    let users = [];

    allUsers?.data.some((el) => {
      if (el.organization === orgs.id) {
        users.push(el);
      }
    });
    let userLength = users.length;
    let orgLength = orgs.children ? orgs.children.length : 0;
    users = [];

    if (orgs.disableUserCreation == true) {
      if (tenant == "psa") {
        return `${orgs.name} (${orgLength} orgs)`;
      } else {
        return `${orgs.displayName} (${orgLength} orgs)`;
      }
    } else {
      if (tenant == "psa") {
        return `${orgs.name} (${userLength} users, ${orgLength} orgs)`;
      } else {
        return `${orgs.displayName} (${userLength} users, ${orgLength} orgs)`;
      }
    }
  };
  const orgTitle = "User filtering is not available for this organization";
  useMemo(() => {
    if (!OrgList?.allOrgs?.data) {
      return;
    }
    setDropdownTreeMeta(
      treeUtil.createTreeForDropdown(OrgList?.allOrgs?.data, selectedValues, orgLabelFunc, tenant, orgTitle)
    );
    setSelectOrgLabel(GetSelectedLabel(selectedValues ?? []));
  }, [OrgList?.allOrgs?.data, selectedValues, allUsers?.data]);

  useMemo(() => {
    const updatedLabel = GetSelectedLabel(rolesselectedValues);
    selectRoleLabelRef.current = updatedLabel;
  }, [rolesselectedValues]);

  useMemo(() => {
    const updatedLabel = GetSelectedLabel(entitieselectedValues);
    selectEntiteLabelRef.current = updatedLabel;
  }, [entitieselectedValues]);
  const { t } = useTranslation();
  return [
    {
      accessorFn: (row) => {
        return UserNameAvatar(row);
      },
      id: "avatar",
      header: "avatar",
      enableHiding: false,
      size: 10,
    },
    {
      accessorKey: "name", //access nested data with dot notation
      header: "USER NAME",
      size: 130,
      Cell: ({ cell }) => {
        return (
          <Link
            onClick={() => navigate(`/ciam/v1/${tenant}/users/edit/${cell.row.original.id}`)}
            className="user-name-link"
          >
            {cell.row.original.name}
          </Link>
        );
      },
      filterFn: (row, id, filterValue) => {
        let filtered = { key: "name", value: filterValue };
        let annotatedData = { data: [row.original], filterCriteria: filtered };
        if (filterValue.length > 0) {
          var isMatch = Filters.simpleTextFilter(annotatedData).length ? true : false;
          return isMatch;
        }
      },
      muiFilterTextFieldProps: {
        InputProps: {
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon sx={{ color: "rgb(184, 194, 209)" }} fontSize="small" />
            </InputAdornment>
          ),
        },
      },
    },
    {
      accessorKey: "givenName",
      header: "GIVEN NAME",
      size: 130,
      filterFn: (row, id, filterValue) => {
        let filtered = { key: "givenName", value: filterValue };
        let annotatedData = { data: [row.original], filterCriteria: filtered };
        if (filterValue.length > 0) {
          var isMatch = Filters.simpleTextFilter(annotatedData).length ? true : false;
          return isMatch;
        }
      },
      muiFilterTextFieldProps: {
        InputProps: {
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon sx={{ color: "rgb(184, 194, 209)" }} fontSize="small" />
            </InputAdornment>
          ),
        },
      },
    },
    {
      accessorKey: "familyName",
      header: "FAMILY NAME",
      size: 130,
      filterFn: (row, id, filterValue) => {
        let filtered = { key: "familyName", value: filterValue };
        let annotatedData = { data: [row.original], filterCriteria: filtered };
        if (filterValue.length > 0) {
          var isMatch = Filters.simpleTextFilter(annotatedData).length ? true : false;
          return isMatch;
        }
      },
      muiFilterTextFieldProps: {
        InputProps: {
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon sx={{ color: "rgb(184, 194, 209)" }} fontSize="small" />
            </InputAdornment>
          ),
        },
      },
    },
    {
      accessorKey: "organization",
      header: "ORGANIZATION",
      Cell: ({ cell }) => {
        const org_name = OrgList?.allOrgs?.data
          ?.filter((o) => o.id === cell.getValue())
          .map((o) => (tenant == "psa" ? o?.name : o?.displayName));
        return org_name;
      },
      Filter: ({ header }) =>
        dropdownTreeMeta && (
          <DropdownTreeSelect
            data={dropdownTreeMeta?.dropdownTree}
            keepOpenOnSelect={true}
            texts={{ placeholder: selectOrgLabel }}
            onChange={(currentNode) => {
              const value = treeUtil.selectDeep(
                currentNode.id,
                currentNode.checked ? "select" : "deselect",
                dropdownTreeMeta
              );
              header.column.setFilterValue(value);
              setSelectedValues(value);
            }}
          />
        ),
      filterFn: (row, id, filterValue) => {
        const filterobject = { key: "organization", value: filterValue };
        if (filterValue.length > 0) {
          var isMatch = Filters.nestedArrayContentFilter({ row, filterobject });
          return isMatch.length > 0 ? true : false;
        }
      },
      size: 150,
    },

    {
      id: "userRole",
      accessorKey: "roles",
      header: "ROLE(s)",
      size: 172,
      filterSelectOptions: roleFilterSelect,
      filterVariant: "multi-select",
      filterFn: (row, id, filterValue) => {
        setRolesSelectedValues(() => (Array.isArray(filterValue) ? filterValue : [filterValue]));
        if (filterValue.length > 0) {
          const roleFc = {
            key: "name",
            value: filterValue,
          };
          let isMatch = Filters.filterArrays({
            roles_collection: roleEntityList?.rolesOnly,
            roleFc,
            row,
            filterKey: "roles",
          });
          return isMatch.length > 0 ? true : false;
        }
      },

      muiFilterTextFieldProps: useMemo(
        () => ({
          className: "roleFilterInput",
          label: selectRoleLabelRef.current,
          InputLabelProps: {
            shrink: false,
          },
        }),
        [selectRoleLabelRef.current]
      ),

      Cell: ({ cell }) => {
        let roleSet = [];
        roleEntityList?.rolesOnly?.filter((role) => {
          cell.getValue()?.map((userRoleId) => {
            if (role.id == userRoleId) {
              roleSet.push(role.name);
            }
          });
        });

        return (
          <RoleEntityShowMore data={roleSet} showProductBasedRoles={tenantSvc?.featureToggle?.showRolesByProduct} />
        );
      },
    },
    tenantSvc?.featureToggle?.isEntityPresent && {
      id: "userEntity",
      accessorKey: "roles",
      header: "ENTITIES",
      filterVariant: "multi-select",
      filterSelectOptions: entityFilterSelect,
      size: 170,
      filterFn: (row, id, filterValue) => {
        setEntitieSelectedValues(() => (Array.isArray(filterValue) ? filterValue : [filterValue]));
        if (filterValue.length > 0) {
          const roleFc = {
            key: "name",
            value: filterValue,
          };
          let isMatch = Filters.filterArrays({
            roles_collection: roleEntityList?.entitiesOnly,
            roleFc,
            row,
            filterKey: "roles",
          });
          return isMatch.length > 0 ? true : false;
        }
      },
      muiFilterTextFieldProps: useMemo(
        () => ({
          className: "entityFilterInput",
          label: selectEntiteLabelRef.current,
          InputLabelProps: {
            shrink: false,
          },
        }),
        [selectEntiteLabelRef.current]
      ),

      Cell: ({ cell }) => {
        let entitySet = [];
        roleEntityList?.entitiesOnly?.filter((entity) => {
          cell.getValue()?.map((userEntity) => {
            if (entity.id === userEntity) {
              entitySet.push(entity.name);
            }
          });
        });

        return (
          <RoleEntityShowMore data={entitySet} showProductBasedRoles={tenantSvc?.featureToggle?.showRolesByProduct} />
        );
      },
    },

    {
      accessorKey: "description",
      header: "DESCRIPTION",
      size: 180,
      Cell: ({ cell }) => {
        const text = cell.row.original.description ? cell.row.original.description.toString().substring(0, 20) : "";
        return (
          <Tooltip title={cell.row.original.description}>
            <Box sx={{ cursor: "pointer", height: "40px", display: "flex", alignItems: "center" }}>
              <Stack direction="row" sx={{ color: "#2E3358" }}>
                {text}
                {text.length > 19 ? <MoreHorizIcon fontSize="small" color="primary" sx={{ alignSelf: "end" }} /> : ""}
              </Stack>
            </Box>
          </Tooltip>
        );
      },
      filterFn: (row, id, filterValue) => {
        let filtered = { key: "description", value: filterValue };
        let annotatedData = { data: [row.original], filterCriteria: filtered };
        if (filterValue.length > 0) {
          var isMatch = Filters.simpleTextFilter(annotatedData).length ? true : false;
          return isMatch;
        }
      },
      muiFilterTextFieldProps: {
        InputProps: {
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon sx={{ color: "rgb(184, 194, 209)" }} fontSize="small" />
            </InputAdornment>
          ),
        },
      },
    },
    {
      accessorKey: "email",
      header: "EMAIL",
      size: 300,
      filterFn: (row, id, filterValue) => {
        let filtered = { key: "email", value: filterValue };
        let annotatedData = { data: [row.original], filterCriteria: filtered };
        if (filterValue.length > 0) {
          var isMatch = Filters.simpleTextFilter(annotatedData).length ? true : false;
          return isMatch;
        }
      },
      muiFilterTextFieldProps: {
        className: "emailFilterInput",
        InputProps: {
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon sx={{ color: "rgb(184, 194, 209)" }} fontSize="small" />
            </InputAdornment>
          ),
        },
      },
    },
    {
      accessorKey: "phone",
      header: "TELEPHONE",
      size: 150,
      filterFn: (row, id, filterValue) => {
        let filtered = { key: "phone", value: filterValue };
        let annotatedData = { data: [row.original], filterCriteria: filtered };
        if (filterValue.length > 0) {
          var isMatch = Filters.simpleTextFilter(annotatedData).length ? true : false;
          return isMatch;
        }
      },
      muiFilterTextFieldProps: {
        InputProps: {
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon sx={{ color: "rgb(184, 194, 209)" }} fontSize="small" />
            </InputAdornment>
          ),
        },
      },
    },
    {
      accessorKey: "active",
      header: "STATUS",
      muiFilterTextFieldProps: {
        placeholder: "Select",
        className: "status-input-field",
        InputLabelProps: {
          shrink: false,
        },
      },
      size: 120,
      filterVariant: "select",
      filterSelectOptions: ["All", "Active", "Inactive"],
      muiTableHeadCellFilterTextFieldProps: {
        InputProps: {
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon sx={{ color: "rgb(184, 194, 209)" }} fontSize="small" />
            </InputAdornment>
          ),
        },
      },
      filterFn: (row, id, filterValue) => {
        if (filterValue != "All") {
          let fo = { key: "active", value: filterValue == "Inactive" ? false : true };
          let annotatedData = { data: [row.original], filterCriteria: fo };
          var isMatch = Filters.exactMatch(annotatedData).length ? true : false;
          return isMatch;
        }
      },
      Cell: ({ cell }) => {
        return cell.getValue() === true ? (
          <span className="status-active">{t("list_user.status.active")}</span>
        ) : (
          <span className="status-inactive">{t("list_user.status.inactive")}</span>
        );
      },
    },
    tenantSvc?.featureToggle?.showTimestampsCols && {
      accessorFn: (originalRow) => {
        return new Date(originalRow?.createTimestamp);
      },
      id: "createTimestamp",
      header: "CREATED AT",
      filterVariant: "datetime-range",
      Cell: ({ cell }) => {
        const timestamp = cell.row.original["createTimestamp"];
        return FormatDateTimeReport(timestamp);
      },
      muiFilterTextFieldProps: ({ rangeFilterIndex }) => ({
        InputProps: {
          placeholder: rangeFilterIndex === 0 ? "Start date" : "End date",
        },
        inputProps: {
          title: dateFormat.DATE_FORMAT_FILTER_USER_LIST,
        },
      }),
      muiTableHeadCellProps: {
        className: "date-range-input-field-header",
      },
      muiTableBodyCellProps: {
        className: "date-range-table-td",
      },
    },
    tenantSvc?.featureToggle?.showTimestampsCols && {
      accessorFn: (originalRow) => {
        return originalRow?.modifyTimestamp ? new Date(originalRow?.modifyTimestamp) : "";
      },
      id: "modifyTimestamp",
      header: "MODIFIED AT",
      filterVariant: "datetime-range",
      Cell: ({ cell }) => {
        const timestamp = cell.row.original["modifyTimestamp"];
        return FormatDateTimeReport(timestamp);
      },
      muiFilterTextFieldProps: ({ rangeFilterIndex }) => ({
        InputProps: {
          placeholder: rangeFilterIndex === 0 ? "Start date" : "End date",
        },
        inputProps: {
          title: dateFormat.DATE_FORMAT_FILTER_USER_LIST,
        },
      }),
      muiTableHeadCellProps: {
        className: "date-range-input-field-header",
      },
      muiTableBodyCellProps: {
        className: "date-range-table-td",
      },
    },
  ].filter(Boolean);
};
