import { CustomOptions } from "../DualListBoxSkeleton/DualListBoxSkeleton";

function ProductSelectionSkeleton() {
  return (
    <div className="persistent-dropdown">
      <div className="dropdown-content">
        <div style={{ padding: "10px" }}>
          <CustomOptions />
        </div>
      </div>
    </div>
  );
}

export default ProductSelectionSkeleton;
