import { List, ListItem, ListItemText } from "@mui/material";
import PropTypes from "prop-types";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

function PersistedProductDropdown({ options, onSelect, selectedProduct, extendLength }) {
  const handleOptionClick = (value) => {
    onSelect(value);
  };

  return (
    <div className={`persistent-dropdown ${extendLength ? "full-length" : "half-length"}`}>
      <div className="dropdown-content">
        <List>
          {options.map((option) => (
            <ListItem
              button
              key={option}
              onClick={() => handleOptionClick(option)}
              className={option === selectedProduct ? "selected" : ""}
            >
              <ListItemText primary={option} />
              {option === selectedProduct && (
                <span>
                  <ArrowRightIcon />
                </span>
              )}
            </ListItem>
          ))}
        </List>
      </div>
    </div>
  );
}
PersistedProductDropdown.propTypes = {
  options: PropTypes.array,
  onSelect: PropTypes.func,
  getDisplayName: PropTypes.func,
  selectedProduct: PropTypes.string,
  extendLength: PropTypes.bool,
};
export default PersistedProductDropdown;
